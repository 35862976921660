<template>
<div id="ResetPasswordModal">
    <transition name="modal">
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(addNewPassword)">
        <div class="modal-mask">
            <div class="modal modal-wrapper">
                <div class="modal-dialog" role="document">
                    <div class="modal-content" style="background-color: white;">
                        <div class="modal-header p-0 pt-1">
                            <div class="d-flex col-md-11 p-0">
                                <h5 class="m-2">Reset Password ({{ userId }})</h5>
                            </div>
                            <button type="button" class="close mr-2" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true" @click="$emit('close')">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">

                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <validation-provider name="password" rules="required" v-slot="{ errors }">
                                                    <label for="password" class=" form-control-label">Password</label>
                                                    <input type="password" id="password" name="password" v-model="resetPassword.password" placeholder="Enter Password..." class="au-input au-input--full form-control" :class="(errors.length > 0 ? ' is-invalid' : '')" />
                                                    <span class="invalid-feedback">Password Required</span>
                                                </validation-provider>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <validation-provider name="conpassword" rules="required" v-slot="{ errors }">
                                                    <label for="conpassword" class=" form-control-label">Confirm
                                                        Password</label>
                                                    <input type="password" id="conpassword" name="conpassword" v-model="resetPassword.password_confirmation" placeholder="Enter Confirm Password..." class="au-input au-input--full form-control" :class="(errors.length > 0 ? ' is-invalid' : '')" />
                                                    <span class="invalid-feedback">Confirm Password Required</span>
                                                </validation-provider>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="custom-control custom-checkbox mr-3 mt-1">
                                                <input type="checkbox" class="custom-control-input" v-model="resetPassword.not_ask_to_reset" id="customCheck1" />
                                                <label class="custom-control-label" for="customCheck1">Do not reset
                                                    at login</label>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="custom-control custom-checkbox mr-2 mt-1">
                                                <input type="checkbox" class="custom-control-input" v-model="resetPassword.user_instructions_email" id="customCheck2" />
                                                <label class="custom-control-label" for="customCheck2">Email login
                                                    instructions</label>
                                            </div>
                                        </div>
                                    </div>

                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="$emit('close')">Close</button>
                            <button type="submit" class="btn btn-primary" >Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </form>
      </ValidationObserver>
    </transition>
</div>
</template>

<script>
import vue from 'vue'
export default {
    name: 'ResetPasswordModal',
    data: () => ({
        resetPassword: {
            user_id:'',
            password: '',
            password_confirmation: "",
            not_ask_to_reset: "",
            user_instructions_email: '',
        },
    }),
    props: {
        userId: {
            type: Number,
            required: true,
        },
        modalVisible: Object,
      },
      methods: {
        closeModal() {
            this.$emit('close');
        },
        addNewPassword() {
            let _vm = this;
            _vm.resetPassword.user_id = _vm.userId;
            _vm.axios
                .post("/reset-password", _vm.resetPassword)
                .then(function () {
                  _vm.resetPassword = {
                    password: '',
                    password_confirmation: ''
                  };
                  _vm.$emit('close');
                  _vm.getAll();
                })
                .catch(function (error) {});
        },
    },
    computed: {

    },
    mounted() {

    }
}
</script>

<style scoped>
.nowrap {
    white-space: nowrap;
}

.inthei {
    height: 33px;
}

.bgcolor {
    background-color: #d3d3d366;
    padding-top: 0px;
    padding-bottom: 0px;
}

.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .3s ease;
}

.modal-wrapper {
    display: table-cell;
    vertical-align: middle;
}

.modal-body {
    max-height: calc(100vh - 180px);
    overflow-y: auto;
}
</style>
