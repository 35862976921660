import { _ } from "vue-underscore";
export default {
	computed: {
		// permissionsList() {
		// 	if (this.$store.state.menuList && this.$store.state.menuList.menu_link) {
		// 		return this.$store.state.menuList.menu_link === window.location.pathname ? this.$store.state.menuList.permissions : [];
		// 	} else {
		// 		return [];
		// 	}
		// },
		// permissionsList() {
		// 	const currentPath = window.location.pathname;
		// 	if (this.$store.state.menuList && this.$store.state.menuList.menu_link) {
		// 		if (this.$store.state.menuList.menu_link === currentPath) {
		// 			return this.$store.state.menuList.permissions;
		// 		}
		// 	}
	},
	methods: {
		isAllowToAddPermission() {
			const menuList = this.$store.state.menuList;
			const currentRoute = this.$route.path;
			let hasPermission = false;
			menuList.forEach(item => {
				if (item.menu_link === currentRoute && _.where(item.permissions, { name: 'Store' }).length > 0) {
					hasPermission = true;
				} else if (item.child && item.child.length > 0) {
					item.child.forEach(element => {
						if (element.menu_link === currentRoute && _.where(element.permissions, { name: 'Store' }).length > 0) {
							hasPermission = true;
						} else if (element.child && element.child.length > 0) {
							element.child.forEach(subElement => {
								if (subElement.menu_link === currentRoute && _.where(subElement.permissions, { name: 'Store' }).length > 0) {
									hasPermission = true;
								}
							});
						}
					});
				}
			});
			return hasPermission;
		},
		isAllowToShowPermission() {
			const menuList = this.$store.state.menuList;
			const currentRoute = this.$route.path;
			let hasPermission = false;
			menuList.forEach(item => {
				if (item.menu_link === currentRoute && _.where(item.permissions, { name: 'Show' }).length > 0) {
					hasPermission = true;
				} else if (item.child && item.child.length > 0) {
					item.child.forEach(element => {
						if (element.menu_link === currentRoute && _.where(element.permissions, { name: 'Show' }).length > 0) {
							hasPermission = true;
						} else if (element.child && element.child.length > 0) {
							element.child.forEach(subElement => {
								if (subElement.menu_link === currentRoute && _.where(subElement.permissions, { name: 'Show' }).length > 0) {
									hasPermission = true;
								}
							});
						}
					});
				}
			});
			return hasPermission;
		},
		isAllowToDeletePermission() {
			const menuList = this.$store.state.menuList;
			const currentRoute = this.$route.path;
			let hasPermission = false;
			menuList.forEach(item => {
				if (item.menu_link === currentRoute && _.where(item.permissions, { name: 'Delete' }).length > 0) {
					hasPermission = true;
				} else if (item.child && item.child.length > 0) {
					item.child.forEach(element => {
						if (element.menu_link === currentRoute && _.where(element.permissions, { name: 'Delete' }).length > 0) {
							hasPermission = true;
						} else if (element.child && element.child.length > 0) {
							element.child.forEach(subElement => {
								if (subElement.menu_link === currentRoute && _.where(subElement.permissions, { name: 'Delete' }).length > 0) {
									hasPermission = true;
								}
							});
						}
					});
				}
			});
			return hasPermission;
		},
		isAllowToUpdatePermission() {
			const menuList = this.$store.state.menuList;
			const currentRoute = this.$route.path;
			let hasPermission = false;
			menuList.forEach(item => {
				if (item.menu_link === currentRoute && _.where(item.permissions, { name: 'Update' }).length > 0) {
					hasPermission = true;
				} else if (item.child && item.child.length > 0) {
					item.child.forEach(element => {
						if (element.menu_link === currentRoute && _.where(element.permissions, { name: 'Update' }).length > 0) {
							hasPermission = true;
						} else if (element.child && element.child.length > 0) {
							element.child.forEach(subElement => {
								if (subElement.menu_link === currentRoute && _.where(subElement.permissions, { name: 'Update' }).length > 0) {
									hasPermission = true;
								}
							});
						}
					});
				}
			});
			return hasPermission;
		},
		isAllowToUserStatusPermission() {
			const menuList = this.$store.state.menuList;
			const currentRoute = this.$route.path;
			let hasPermission = false;
			menuList.forEach(item => {
				if (item.menu_link === currentRoute && _.where(item.permissions, { name: 'User Active|Inactive' }).length > 0) {
					hasPermission = true;
				} else if (item.child && item.child.length > 0) {
					item.child.forEach(element => {
						if (element.menu_link === currentRoute && _.where(element.permissions, { name: 'User Active|Inactive' }).length > 0) {
							hasPermission = true;
						} else if (element.child && element.child.length > 0) {
							element.child.forEach(subElement => {
								if (subElement.menu_link === currentRoute && _.where(subElement.permissions, { name: 'User Active|Inactive' }).length > 0) {
									hasPermission = true;
								}
							});
						}
					});
				}
			});
			return hasPermission;
		},
	}
};